const dev = {
  api: {
    URL: 'https://api.goagent.com.ng/api/v1',
    // URL: 'http://127.0.0.1:8000/api/v1',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

const stage = {
  api: {
    // URL: 'https://api.goagent.com.ng/api/v1',
    URL: 'https://stagingadmin.goagent.com.ng/api/v1',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

const prod = {
  api: {
    URL: 'https://api.goagent.com.ng/api/v1',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  TOKEN_EXPIRY_TIME: 5, // in minutes
  APP_INACTIVITY_TIME: 10, // in minutes
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'stage':
    config = stage;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default {
  ...config,
};
